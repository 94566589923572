
<template>
  <v-container id="agreements" tag="section">

    <!-- =======================================================
                                Overlay
    ============================================================-->
    <v-overlay :value="overlay">
      <v-progress-circular :size="70" :width="7" color="blue" indeterminate></v-progress-circular>
    </v-overlay>


    <!-- =======================================================
                                Dialog
    ============================================================-->
    <div class="text-center">
      <v-dialog v-model="dialog.value" width="500">
        <v-card>
          <v-card-title class="headline red white--text">
            <v-spacer />
            {{dialog.title}}
            <v-spacer />
          </v-card-title>

          <v-card-text class="mt-5">{{dialog.message}}</v-card-text>

          <v-divider></v-divider>

          <v-card-actions class="pa-5">
            <v-btn text @click="dialogClear()">Não</v-btn>
            <v-spacer></v-spacer>
            <v-btn text @click="dialog.action()">Sim</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <!-- =======================================================
                                Main
    ============================================================-->
    <v-btn color="green" class="ma-2 white--text" to="/pages/agreements/create">
      <v-icon left dark>mdi-plus</v-icon>Adicionar
    </v-btn>

    <tables
      :title="title"
      :columns="columns"
      :linesData="linesData"
      @action="action($event)"
      :actionsOptions="actionsOptions"
    />
  </v-container>
</template>

<script>
import agreementsService from "../../../../service/agreementsService";

export default {
  name: "Agreements",
  components: {
    Tables: () => import("../../../../components/cms/Tables")
  },
  data: () => ({
    title: "Acordos e Convenções Cadastrados",
    columns: ["Desctrição", "Categoria", "Período", "Ações"],
    actionsOptions: ["edit", "delete"],
    linesData: [],
    approvedDialog: false,
    overlay: false,
    dialog: {
      title: "",
      message: "",
      type: "",
      id: "",
      value: false,
      action: null
    }
  }),
  methods: {
    action($event) {
      switch ($event.type) {
        case "edit":
          this.edit($event.id);
          break;
        case "delete":
          const data = {
            title: "Excluir Acordos e Convenções",
            message: "Deseja excluir esse acordo?",
            type: "delete",
            id: $event.id,
            action: this.deleteUser
          };
          this.dialogAction(data);
          break;
        default:
          break;
      }
    },
    dialogAction(data) {
      this.dialog = Object.assign({}, data);
      this.dialog.value = true;
    },
    dialogClear() {
      this.dialog = {
        title: "",
        message: "",
        type: "",
        id: "",
        value: false,
        action: null
      };
    },
    getAll() {
      this.overlay = true;
      agreementsService.get({}).then(res => {
        this.overlay = false;
        this.linesData = res.data.map(agreement => {
          return {
            id: agreement.id,
            lines: [
              {width:35 ,name:agreement.description},
              {width:35 ,name:agreement.category},
              {width:15 ,name:agreement.year_end? `${agreement.year_init} / ${agreement.year_end}`:agreement.year_init}
            ]
          };
        });
      });
    },
    edit(id) {
      this.$router.push({ path: `agreements/edit/${id}` });
    },
    deleteUser() {
      agreementsService.delete(this.dialog.id).then(res => {
        this.getAll();
        this.dialogClear();
      });
    }
  },
  mounted() {
    this.getAll();
  }
};
</script>

